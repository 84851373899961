export const colorsTheme = {
  white: '#FFFFFF',
  black: '#000000',
  danger: '#F2383A',

  'gradient-deg': '294deg',
  'gradient-primary': '#02B0FF',
  'gradient-secondary': '#0276AB',

  'primary-color': '#02B0FF',
  'secondary-color': '#0276AB',
  'auxiliary-color': '#C2E8F9',

  'bg-color': '#FFFFFF',
  'bg-secondary-color': '#121212',
  'bg-auxiliary-color': '#EDEDED',

  'bg-circle-color': '#FFFFFF',
  'bg-circle-hover': '#02B0FF',
  'bg-circle-text-color': '#000',
  'bg-circle-text-color-hover': '#FFFFFF',

  'filter-button-background': '#02B0FF',
  'filter-button-color': '#FFFFFF',
  'filter-button-border': '#02B0FF',
  'entry-message-color': '#02B0FF',

  'bg-modal-color': '#FFFFFF',

  'dark-color': '#3F3F3F',

  'text-primary': '#1E1E1E',
  'text-secondary': '#C2E8F9;',
  'text-gray': '#4F4F4F',

  'input-color': '#1E1E1E',
  'bg-input-color': '#FFFFFF',
  'info-color': '#02B0FF',
  'disabled-color': '#e2e2e2',
  'card-color': '#EEEEEE',
  'modal-border-color': '#ADB5BD',
  'border-color': '#0276AB',

  'login-gradient':
    'linear-gradient(158.25deg, #0299DE 4.43%, rgba(33, 37, 41, 0) 53.34%),linear-gradient(322.86deg, rgba(202, 97, 32, 0.6) 6.61%, rgba(202, 97, 32, 0) 45.8%)',
  'login-gradient-complement': '#121212',

  'echeck-label-color': '#000',
  'echeck-border-color': '#D2D2D2',
  'echeck-text-color': '#000',
  'echeck-bar-color1': '#0276AB',
  'echeck-bar-color2': '#0276AB'
};
